import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {notHaveErrors, validateFields, validateForm} from '../../../utils/form';
import * as yup from 'yup';
import Form from '../../../components/UI/Form/Form';
import Input from '../../../components/UI/Input/Input';
import BottomButtons from '../../../components/UI/Form/BottomButtons/BottomButtons';
import Button from '../../../components/UI/Button/Button';
import TwoColumns from '../../../components/UI/Form/TwoColumns/TwoColumns';
import Checkbox from '../../../components/UI/Checkbox/Checkbox';
import Select from '../../../components/UI/Select/Select';
import {useDeleteUser, useSaveUser, useUserLazy} from '../../../apollo/client.queries';
import classes from '../Project.module.scss';
import {toastMessage} from '../../../components/UI/Toast/Toast';

const ProjectUsersForm = (props) => {
    const {data, refetch, roles} = props;

    const {t} = useTranslation();
    const params = useParams();
    const navigate = useNavigate();

    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});

    const [getUserLazy, userData] = useUserLazy();
    const [saveUser] = useSaveUser();
    const [deleteUser] = useDeleteUser();

    useEffect(() => {
        getUserLazy({variables: {
                id: params.tabId !== "new" ? params.tabId ?? "" : "",
                projectId: +params.projectId ?? 0
            }}).then();
    }, [params]);

    useEffect(() => {
        if (userData.data && userData.data?.users?.length > 0) {
            const user = {...userData.data.users[0]};

            data.projects[0].places.forEach(x => {
                if(user.places.includes(x.id)) {
                    user[`place-${x.id}`] = true;
                }
            });

            setValues(user);
        } else {
            setValues({...values, sendAgain: true, hideSendAgain: true})
        }
    }, [userData?.data]);

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });
        setValues(newValues);
    }

    const validationRules = {
        email: yup.string().required(t("Require")),
        name: yup.string().required(t("Require")),
        role: yup.string().required(t("Require"))
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const formSubmitted = (e) => {
        e.preventDefault();
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors))
                saveData();
            else
                setErrors(errors);
        });
    }

    const saveData = () => {
        const variables = {
            input: {
                userId: values?.id ?? "",
                projectId: +params.projectId,
                name: values.name,
                email: values.email,
                role: values.role,
                places: [],
                sendAgain: values.sendAgain,
                active: values.active
            }
        };

        data.projects[0].places.forEach(x => {
            if(values[`place-${x.id}`] === true) {
                variables.input.places.push(x.id);
            }
        });

        saveUser({variables: variables}).then(result => {
            toastMessage(true, t("Successfully saved."))
            navigate(`/projects/${params.projectId}/users`);
            refetch();
        });
    }

    const onDelete = () => {
        const variables = {
            input: {
                userId: values?.id,
                projectId: +params.projectId
            }
        };

        deleteUser({variables: variables}).then(result => {
            if (result) {
                toastMessage(true, t("User is deleted."))
                navigate(`/projects/${params.projectId}/users`);
                refetch();
            } else {
                console.log("Error");
            }
        });
    }

    const onCancel = () => {
        navigate(`/projects/${params.projectId}/users`);
    }

    if (userData.loading) {
        return (
            <div>Loading</div>
        );
    }

    if (userData.error) {
        return (
            <div>Error</div>
        );
    }

    if (params.tabId !== "new" && userData.data?.users?.length === 0) {
        return (
            <div className={classes.empty}>No data found.</div>
        );
    }


    return (
        <>
            <Form>
                <TwoColumns title={t("New user")}>
                    <Input
                        name="email"
                        type="email"
                        label={t("Email")}
                        {...regField}
                    />

                    <Input
                        name="name"
                        label={t("User name")}
                        disabled={params.tabId !== "new" }
                        {...regField}
                    />

                    <Select
                        name="role"
                        label={t("Role")}
                        options={roles}
                        {...regField}
                    />
                </TwoColumns>

                {values.role === "USER" && <>
                    <TwoColumns title={t("Can access to places")}>
                        {data.projects[0].places.map(x =>
                            <Checkbox
                                key={`place-${x.id}`}
                                name={`place-${x.id}`}
                                label={x.name}
                                {...regField}
                            />)}
                    </TwoColumns>
                    <br />
                </>}

                {!values.hideSendAgain ?
                <Checkbox
                    name="sendAgain"
                    label={t("Send invite again")}
                    {...regField}
                />: undefined }

                <Checkbox
                    name="active"
                    label={t("Active")}
                    {...regField}
                />

                <BottomButtons>
                    <Button type="second" onClick={onDelete}>Delete</Button>
                    <div></div>
                    <Button type="primary" onClick={formSubmitted}>Save</Button>
                    <Button type="second" onClick={onCancel}>Cancel</Button>
                </BottomButtons>
            </Form>
        </>
    );
};

ProjectUsersForm.defaultProps = {
    roles: [
        {label: "Manager", value: "MANAGER"},
        {label: "User", value: "USER"},
    ]
};

export default ProjectUsersForm;
