import React from 'react';
import Page from '../../components/UI/Page/Page';
import Panel from '../../components/UI/Panel/Panel';
import Button from '../../components/UI/Button/Button';
import {useTranslation} from 'react-i18next';
import Table from '../../components/UI/Table/Table';
import {useNavigate} from 'react-router-dom';
import {useProjects} from '../../apollo/client.queries';
import classes from './Projects.module.scss';
import {BsArrowRightShort} from 'react-icons/bs';

const Projects = (props) => {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const title = t("Projects");

    const {data, loading, error} = useProjects();

    const actions = () => {
        return (
            <Button type="primary" onClick={onAddCompany}>{t("Add project")}</Button>
        );
    }

    const onAddCompany = () => {
        navigate(`/projects/new`);
    }

    const search = () => {
        return (<></>);
    }

    const onRowClick = (data) => {
        const {id, isManager} = data;

        if (isManager)
            navigate(`/projects/${id}`);
    }

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error!</div>;

    return (
        <>
            <Page title={title}>
                <Panel actions={actions} search={search} />

                <Table
                    header={false}
                    data={data.projects}
                    onRowClick={onRowClick}
                    columns={[
                        {
                            Header: t('Name'),
                            accessor: 'name',
                            Cell: (cellData) => {
                                const {isManager} = cellData.row.original;
                                return (<div style={{cursor: 'pointer'}}>
                                    <div className={classes.tableTitle}>{t("Project")}</div>
                                    <div className={classes.tableName}>{cellData.value}</div>
                                    <div className={classes.tableAction}>{isManager ? <> {t("Edit project")} <BsArrowRightShort /> </>: t("Read only")}</div>
                                </div>)
                            }
                        },
                        {
                            Header: t('Projects'),
                            accessor: 'places',
                            className: classes.info,
                            Cell: (cellData) => <div style={{cursor: 'pointer'}}>
                                <div className={classes.tableTitle}>{t("Places")}</div>
                                <div className={classes.tableName}>{cellData.value.length}</div>
                                <div className={classes.tableAction}>&nbsp;</div>
                            </div>
                        },
                        {
                            Header: t('Users'),
                            accessor: 'users',
                            className: classes.info,
                            Cell: (cellData) => <div style={{cursor: 'pointer'}}>
                                <div className={classes.tableTitle}>{t("Users")}</div>
                                <div className={classes.tableName}>{cellData.value.length}</div>
                                <div className={classes.tableAction}>&nbsp;</div>
                            </div>
                        },
                    ]}
                />
            </Page>
        </>
    );
};

Projects.defaultProps = {};

export default Projects;
