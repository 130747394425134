import React, {useState} from 'react';
import Menu from './Menu/Menu';
import classes from './Dashboard.module.scss';
import {useAuth} from 'react-oidc-context';
import Select from '../../UI/Select/Select';
import i18n from 'i18next';
import {AiOutlineMenu} from 'react-icons/ai';
import Toast from '../../UI/Toast/Toast';

const Dashboard = (props) => {
    const {languages} = props;

    const auth = useAuth();

    const [menu, setMenu] = useState(true);
    const [language, setLanguage] = useState({language:  localStorage.getItem("i18next_language") ?? 'lv'});

    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    if (auth.error) {
        return <div>Oops... {auth.error.message}</div>;
    }

    const onLanguageChange = (e) => {
        setLanguage({language: e.target.value});
        localStorage.setItem("i18next_language", e.target.value);
        i18n.changeLanguage(e.target.value);
    }

    if (auth.isAuthenticated) {
        return (
            <>
                <div className={classes.dashboard}>
                    {menu ? <Menu/> : undefined}
                    <div>
                        <div className={classes.header}>
                            <div>
                                <div>
                                    {!menu ? <AiOutlineMenu className={classes.menu} /> : undefined}
                                    <a href="/" className={classes.logo}>loyaty</a>
                                </div>
                                <div></div>
                                <Select
                                    name="language"
                                    style={{margin: 0}}
                                    options={languages}
                                    value={language}
                                    onChange={onLanguageChange}
                                />
                            </div>
                        </div>
                        {props.children}
                    </div>
                </div>
                <Toast />
            </>
        );
    }

    return (
        <>Please login...</>
    )
};

Dashboard.defaultProps = {
    languages: [
        {label: "Latviešu", value: "lv"},
        {label: "Русский", value: "ru"},
        {label: "English", value: "en"},
    ]
};

export default Dashboard;
