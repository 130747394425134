import React from 'react'
import classes from './Checkbox.module.scss'
import {haveDefaultValue, haveError} from '../../../utils/form';
import cn from 'classnames';

const Checkbox = props => {
    const cls = ['form-field', classes.Checkbox]
    const htmlFor = `checkbox-${Math.random()}`

    const error = haveError(props);
    const defaultValue = haveDefaultValue(props) ?? false;

    if (error) {
        cls.push(classes.invalid)
    }

    const onChange = (e) => {
        props.onChange({
            target: {
                name:  props.name,
                value: e.target.checked
            }
        });
    }

    return (
        <div className={cls.join(' ')}>
            <div>
                <label htmlFor={htmlFor}>
                    <input
                        name={props.name}
                        type="checkbox"
                        id={htmlFor}
                        checked={defaultValue}
                        disabled={props.disabled}
                        onChange={onChange}
                        className={cn({[classes.ColorCheckbox]: props.color})}
                    />
                    {props.color ? <span style={{borderColor: props.color,
                        backgroundColor: defaultValue ? props.color : undefined}}></span> : undefined}
                    {props.label}
                </label>
            </div>
        </div>
    )
}

export default Checkbox
