import React from 'react';
import classes from './OneColumn.module.scss';

const OneColumn = (props) => {
    const {title, children} = props;

    return (
        <>
            <h2>{title}</h2>
            <div className={classes.OneColumn}>
                {children}
            </div>
        </>
    );
};

OneColumn.defaultProps = {};

export default OneColumn;
