import React from 'react';
import classes from './TwoColumns.module.scss';

const TwoColumns = (props) => {
    const {title, children} = props;

    return (
        <>
            <h2>{title}</h2>
            <div className={classes.TwoColumns}>
                {children}
            </div>
        </>
    );
};

TwoColumns.defaultProps = {};

export default TwoColumns;
