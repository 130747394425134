import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useProjects} from '../../apollo/client.queries';
import Button from '../../components/UI/Button/Button';
import Page from '../../components/UI/Page/Page';
import Panel from '../../components/UI/Panel/Panel';
import classes from '../Projects/Projects.module.scss';
import {BsArrowRightShort} from 'react-icons/bs';
import Table from '../../components/UI/Table/Table';
import Select from '../../components/UI/Select/Select';
import Tutorial from '../../components/UI/Tutorial/Tutorial';
import {toastMessage} from '../../components/UI/Toast/Toast';


const Places = (props) => {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const title = t("Places");

    const [project, setProject] = useState(undefined);

    const [places, setPlaces] = useState([]);
    const [tutorial, setTutorial] = useState(false);

    const {data, loading, error} = useProjects();

    useEffect(() => {
        if (!data) {
            return;
        }

        if(data.projects.length === 0) {
            setTutorial(true);
        }

        const selectedProject = +localStorage.getItem("selected_project");
        if (selectedProject && data.projects.find(x => x.id === selectedProject)) {
            setProject({project: selectedProject});
        } else {
            setProject({project: +data.projects[0]?.id ?? 0});
        }
    }, [data]);

    const actions = () => {
        return (
            <Button type="primary" disabled={data.projects[0]?.id === undefined} onClick={onAddPlace}>{t("Add place")}</Button>
        );
    }

    const onAddPlace = () => {
        navigate(`/projects/${project?.project ?? 0}/places/new`);
    }

    const onChangeProject = (e) => {
        setProject({project: e.target.value});
        localStorage.setItem("selected_project", e.target.value);
    }

    const search = () => {
        return (
            <Select
                name="project"
                style={{margin: 0}}
                options={data.projects.map(x => ({label: x.name, value: +x.id}))}
                value={project}
                onChange={onChangeProject}
            />
        );
    }

    const onRowClick = (data) => {
        const {id, canAccess, googleLink} = data;

        if (googleLink === "NEED_HELP") {
            toastMessage(false, t("This place checked for support."));
            return;
        }

        if (canAccess) {
            navigate(`/places/${id}`);
        }
    }

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error!</div>;

    return (
        <>
            <Page title={title}>
                <Panel actions={actions} search={search} />

                <Table
                    header={false}
                    data={data.projects.find(x => x.id === project?.project ?? 0)?.places?.filter(f => f.canAccess) ?? []}
                    onRowClick={() => {}}
                    columns={[
                        {
                            Header: t('Place'),
                            accessor: 'name',
                            Cell: (cellData) => <div style={{cursor: 'pointer'}} onClick={() => onRowClick(cellData.row.original)}>
                                <div className={classes.tableTitle}>{t("Place")}</div>
                                <div className={classes.tableName}>{cellData.value}</div>
                                <div className={classes.tableAction}>
                                   {t("Manage place")} <BsArrowRightShort />
                                </div>
                            </div>
                        },{
                            Header: t('Address'),
                            accessor: 'address',
                            Cell: (cellData) => <div style={{cursor: 'pointer'}} onClick={() => onRowClick(cellData.row.original)}>
                                <div className={classes.tableTitle}>{t("Address")}</div>
                                <div className={classes.tableName}>{cellData.value}</div>
                                <div className={classes.tableAction}>&nbsp;</div>
                            </div>
                        }
                    ]}
                />
            </Page>

            <Tutorial show={tutorial}>
                Welcome to Loyaty!<br/><br/>

                Now you don't have any projects.<br/>
                Let's create one!<br/><br/>

                <div>
                    <Button type="primary" onClick={() => navigate(`/projects/new`)}>{t("Create project")}</Button>
                    <Button type="second" onClick={() => navigate(`/projects/new`)}>{t("Skip tutorial")}</Button>
                </div>
            </Tutorial>
        </>
    );
};

Places.defaultProps = {};

export default Places;
