import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import HttpApi from 'i18next-http-backend';

const language = localStorage.getItem("i18next_language") ?? 'lv';
const version = 1;

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(Backend)
    .init({
        lng: language,
        debug: false,
        saveMissing: process.env.REACT_APP_TRANSLATES,
        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },
        backend: {
            backends: [
                LocalStorageBackend,  // primary
                HttpApi               // fallback
            ],
            backendOptions: [{
                expirationTime: 7 * 24 * 60 * 60 * 1000,
                defaultVersion: version
            }, {
                loadPath: `${process.env.REACT_APP_TRANSLATES_URL}/${language}`,
                addPath: `${process.env.REACT_APP_TRANSLATES_URL}/add/${language}`,
            }]
        },
        react: {
            useSuspense: false
        }
    });


export default i18n;
