import React from 'react';
import {useTranslation} from 'react-i18next';
import {useReviews} from '../../../apollo/review.queries';
import {useParams} from 'react-router-dom';
import {MdOutlineMail, MdOutlineSms, MdQrCode2} from 'react-icons/md';
import classes from './PlacesReview.module.scss'
import OneColumn from '../../../components/UI/Form/OneColumn/OneColumn';
import {format, parseISO} from 'date-fns';

const PlaceReview = (props) => {
    const {t} = useTranslation();
    const params = useParams();

    const {loading, error, data} = useReviews({placeId: +params.placeId})

    const Icon = ({type}) => {
        switch (type) {
            case "SMS":
                return (<MdOutlineSms />);
            case "EMAIL":
                return (<MdOutlineMail />);
            case "QR":
                return (<MdQrCode2 />);
            default:
                return (<></>);
        }
    }

    const Review = (review) => {
        const {code, serviceType, reviewedAt, productQuality, serviceQuality, reviewText, name, phone, email} = review;
        const date = parseISO(reviewedAt);

        return (
          <div key={`${code}-${reviewedAt}`} className={classes.review}>
              <div className={classes.icon}><Icon type={serviceType} /></div>
              <div>
                  <div className={classes.message}>
                      <label>Kā jūs vērtējat mūsu preces?</label>
                      {productQuality}
                  </div>
                  <div className={classes.message}>
                      <label>Kā jūs vērtējat mūsu apkalpošanu?</label>
                      {serviceQuality}
                  </div>
                  {reviewText ? <div className={classes.message}>
                      <label>Komentēts</label>
                      {reviewText}
                  </div> : undefined}
              </div>
              <div></div>
              <div className={classes.info}>
                  {format(date,'dd.MM.Y kk:mm')}
                  {name ? <>, {t("Name")}: {name}</> : undefined}
                  {phone ? <>, <a href={`phone:${phone}`}>{phone}</a></> : undefined}
                  {email ? <>, <a href={`mail:${email}`}>{email}</a></> : undefined}
              </div>
          </div>
        );
    }

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error!</div>;

    return (
        <OneColumn title={t("Not published reviews")}>
            {data.reviews?.length > 0
                ? data.reviews.map(x => Review(x))
                : <div className={classes.empty}>{t("No data to display.")}</div>}
        </OneColumn>
    );
};

PlaceReview.defaultProps = {};

export default PlaceReview;
