import React, {useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import {useAuth} from 'react-oidc-context';

const AfterLogin = (props) => {
    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (auth.isAuthenticated) {
            navigate("/");
        }
    }, [auth]);

    return (
        <>
            Please wait...
        </>
    );
};

AfterLogin.defaultProps = {};

export default AfterLogin;
