import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import * as yup from 'yup';
import BottomButtons from '../../../components/UI/Form/BottomButtons/BottomButtons';
import Button from '../../../components/UI/Button/Button';
import Input from '../../../components/UI/Input/Input';
import Form from '../../../components/UI/Form/Form';
import {useDeletePlace, usePlaceLazy, useSavePlace} from '../../../apollo/client.queries';
import {notHaveErrors, validateFields, validateForm} from '../../../utils/form';
import classes from '../Project.module.scss';
import {toastMessage} from '../../../components/UI/Toast/Toast';
import usePlacesAutocomplete, {getGeocode, getLatLng} from 'use-places-autocomplete';
import OneColumn from '../../../components/UI/Form/OneColumn/OneColumn';
import {BsFillPlayCircleFill} from 'react-icons/bs';
import cn from 'classnames';


const ProjectPlaceForm = (props) => {
    const {refetch} = props;

    const skip = ["archipelago", "colloquial_area", "continent", "country", "floor", "landmark", "locality", "geocode", "room", "route", "street_address", "street_number", "transit_station"];

    const {t} = useTranslation();
    const params = useParams();
    const navigate = useNavigate();

    const [values, setValues] = useState({active: true});
    const [errors, setErrors] = useState({});
    const [projects, setProjects] = useState([]);
    const [play, setPlay] = useState(true);

    const [getPlaceLazy, placeData] = usePlaceLazy();
    const [savePlace] = useSavePlace();
    const [deletePlace] = useDeletePlace();

    const { ready, suggestions: { status, data }, setValue} = usePlacesAutocomplete({
        requestOptions: {},
        debounce: 300,
    });

    useEffect(() => {
        getPlaceLazy({variables: {
                id: params.tabId !== "new" ? +params.tabId ?? 0 : 0,
                projectId: +params.projectId ?? 0
            }});
    }, []);

    useEffect(() => {
        if (placeData.data && placeData.data?.places?.length > 0) {
            const place = placeData.data.places[0];
            setValues(place);
        }

        if (placeData.data && placeData.data?.projects?.length > 0) {
            const _projects = placeData.data?.projects.map(x => ({label: x.name, value: x.id}));
            setProjects(_projects);
        }

    }, [placeData.data]);


    const onSelectPlace = (place) => {
        const variables = {
            input: {
                id: 0,
                projectId: +params.projectId,
                name: values.name,
                address: values.address,
                googleLink: place.place_id,
                active: true
            }
        };

        savePlace({variables: variables}).then(result => {
            toastMessage(true, t("Successfully saved."));
            navigate(`/projects/${params.projectId}/places`);
        });
    }

    const onNeedHelp = () => {
        const variables = {
            input: {
                id: 0,
                projectId: +params.projectId,
                name: values.name,
                address: values.address,
                googleLink: "NEED_HELP",
                active: true
            }
        };

        savePlace({variables: variables}).then(result => {
            toastMessage(true, t("Saved. Our support team contact you."));
            navigate(`/projects/${params.projectId}/places`);
        });
    }

    const renderSuggestions = () => {
        return (
            <>
                {data.filter(f => f.types.some(r => skip.includes(r)) === false).map((suggestion) => {
                    const {
                        place_id,
                        structured_formatting: { main_text, secondary_text },
                    } = suggestion;

                    return (
                        <li key={place_id} className={classes.place} onClick={() => onSelectPlace(suggestion)}>
                            <strong>{main_text}</strong> <small>{secondary_text}</small>
                        </li>
                    );
                })}

                {status === "OK" ? <li className={classes.place} onClick={onNeedHelp}>
                        <strong>{t("Can't find my place, please help me.")}</strong>
                        <small>{t("Our support team contact you. It take some time.")}</small>
                    </li>
                    :undefined}
            </>
        );
    }

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });
        setValues(newValues);
    }

    const validationRules = {
        name: yup.string().required(t("Require")),
        address: yup.string().required(t("Require"))
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const onOpenGoogleMap = () => {
        window.open("https://maps.google.com", "_blank")
    }

    const onSearchPlace = () => {
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors))
                setValue(`${values.name} ${values.address}`);
            else
                setErrors(errors);
        });
    }

    const onDelete = () => {
        const variables = {
            id: values?.id
        };

        deletePlace({variables: variables}).then(result => {
            if (result) {
                toastMessage(true, t("Place is deleted."));
                navigate(`/projects/${params.projectId}/places`);
            } else {
                console.log("Error");
            }
        });
    }

    const onCancel = () => {
        navigate(`/projects/${params.projectId}/places`);
    }

    if (placeData.loading) {
        return (
            <div>Loading</div>
        );
    }

    if (placeData.error) {
        return (
            <div>Error</div>
        );
    }

    if (params.tabId !== "new" && placeData.data?.places?.length === 0) {
        return (
            <div className={classes.empty}>No data found.</div>
        );
    }

    if (params.tabId === "new") {
        return (
            <>
                <Form>
                    <OneColumn title={t("Instruction")}>
                        <div className={classes.video} onClick={() => setPlay(!play)}>
                            <img className={classes.instruction} src="/assets/images/instruction.gif" alt={t("Instruction")} />
                            <div className={cn({[classes.hide]:!play})}></div>
                            <div className={classes.play}><BsFillPlayCircleFill /></div>
                        </div>
                    </OneColumn>

                    <OneColumn title={t("Google map")}>
                        <div className={classes.text}>
                            {t("Find your place on Google map. Copy to fields in bottom Place Name and Place Address.")}
                        </div>
                        <div className={classes.center}><Button type="primary" onClick={onOpenGoogleMap}>Google Maps</Button></div>
                    </OneColumn>

                    <OneColumn title={t("Search form")}>
                        <Input
                            name="name"
                            label={t("Place name")}
                            disabled={!ready}
                            {...regField}
                        />

                        <Input
                            name="address"
                            label={t("Place address")}
                            disabled={!ready}
                            {...regField}
                        />

                        <div className={classes.center}><Button type="primary" onClick={onSearchPlace}>Search Place</Button></div>
                    </OneColumn>

                    {status === "OK" ? <OneColumn title={t("Select your place")}>
                        <ul className={classes.list}>{renderSuggestions()}</ul>
                    </OneColumn> : undefined}

                </Form>
            </>
        );
    }

    return (
        <>
            <Form>
                <Input
                    name="name"
                    label={t("Place name")}
                    disabled={true}
                    {...regField}
                />

                <Input
                    name="address"
                    label={t("Place address")}
                    disabled={true}
                    {...regField}
                />

                <BottomButtons>
                    <Button type="second" onClick={onDelete}>Delete</Button>
                    <div></div>
                    <div></div>
                    <Button type="second" onClick={onCancel}>Cancel</Button>
                </BottomButtons>
            </Form>
        </>
    );
};

ProjectPlaceForm.defaultProps = {};

export default ProjectPlaceForm;
