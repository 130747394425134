import React, {useState} from 'react';
import Input from '../../../components/UI/Input/Input';
import TwoColumns from '../../../components/UI/Form/TwoColumns/TwoColumns';
import Checkbox from '../../../components/UI/Checkbox/Checkbox';
import {useTranslation} from 'react-i18next';
import {useDeleteProject, useSaveProject} from '../../../apollo/client.queries';
import Button from '../../../components/UI/Button/Button';
import BottomButtons from '../../../components/UI/Form/BottomButtons/BottomButtons';
import {notHaveErrors, validateFields, validateForm} from '../../../utils/form';
import * as yup from 'yup';
import {useNavigate, useParams} from 'react-router-dom';
import OneColumn from '../../../components/UI/Form/OneColumn/OneColumn';
import {toastMessage} from '../../../components/UI/Toast/Toast';
import Select from 'components/UI/Select/Select';

const ProjectProject = (props) => {
    const {payments} = props;

    const {t} = useTranslation();
    const params = useParams();
    const navigate = useNavigate();

    const [errors, setErrors] = useState({});
    const {values, setValues} = props.data;

    const canAccess = !values.isAdmin && params.projectId !== "new";

    const [saveProject] = useSaveProject();
    const [deleteProject] = useDeleteProject();

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });
        setValues(newValues);
    }

    const validationRules = {
        name: yup.string().required(t("Require")),
        country: yup.string().required(t("Require")),
        address: yup.string().required(t("Require")),
        companyName: yup.string().required(t("Require")),
        vat: yup.string().required(t("Require")),
        bank: yup.string().required(t("Require")),
        swift: yup.string().required(t("Require")),
        count: yup.string().required(t("Require")),
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const formSubmitted = (e) => {
        e.preventDefault();
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors))
                saveData();
            else
                setErrors(errors);
        });
    }

    const saveData = () => {
        const variables = {
            input: {
                id: values?.id ?? 0,
                name: values.name,
                adminId: "0",
                contactName: values.contactName,
                contactMail: values.contactMail,
                contactPhone: values.contactPhone,
                country: values.country,
                address: values.address,
                companyName: values.companyName,
                vat: values.vat,
                bank: values.bank,
                swift: values.swift,
                paymentPeriod: values.paymentPeriod,
                paymentObject: values.paymentObject,
                sumPerObject: +values.sumPerObject,
                count: values.count,
                active: values.active
            }
        };

        saveProject({variables: variables}).then(result => {
            const {data} = result;
            toastMessage(true, t("Successfully saved."));
            navigate(`/projects/${data.saveProject.id}`);
        });
    }

    const onCancel = () => {
        navigate("/projects");
    }

    const onDelete = () => {
        const variables = {
            id: values?.id
        };

        deleteProject({variables: variables}).then(result => {
            if (result) {
                toastMessage(true, t("Project deleted."));
                navigate(`/projects/`);
            } else {
                console.log("Error");
            }
        });
    }

    return (
        <fieldset disabled={canAccess}>
            <OneColumn title={t("Project")}>
                <Input
                    name="name"
                    label={t("Project name")}
                    {...regField}
                />
            </OneColumn>

            <TwoColumns title={t("Company")}>
                <Input
                    name="companyName"
                    label={t("Company name")}
                    {...regField}
                />

                <Input
                    name="vat"
                    label={t("Vat")}
                    {...regField}
                />
            </TwoColumns>


            <TwoColumns title={t("Address")}>
                <Input
                    name="country"
                    label={t("Country")}
                    {...regField}
                />

                <Input
                    name="address"
                    label={t("Address")}
                    {...regField}
                />
            </TwoColumns>


            <TwoColumns title={t("Bank")}>
                <Input
                    name="bank"
                    label={t("Bank")}
                    {...regField}
                />

                <div></div>

                <Input
                    name="swift"
                    label={t("Swift")}
                    {...regField}
                />

                <Input
                    name="count"
                    label={t("Count")}
                    {...regField}
                />
            </TwoColumns>


            <TwoColumns title={t("Payments")}>
                <Select
                    name="paymentPeriod"
                    label={t("Period")}
                    options={payments.periods}
                    {...regField}
                />

                <Select
                    name="paymentObject"
                    label={t("Type")}
                    options={payments.types}
                    {...regField}
                />

                <Input
                    name="sumPerObject"
                    label={t("Sum")}
                    type="number"
                    {...regField}
                />

                <div></div>
            </TwoColumns>


            <TwoColumns title={t("Contact")}>
                <Input
                    name="contactName"
                    label={t("Contact name")}
                    {...regField}
                />

                <div></div>

                <Input
                    name="contactMail"
                    type="email"
                    label={t("Contact mail")}
                    {...regField}
                />

                <Input
                    name="contactPhone"
                    label={t("Contact phone")}
                    {...regField}
                />
            </TwoColumns>


            {values.isAdmin ?
                <>
                    <br />
                    <Checkbox
                        name="active"
                        label={t("Active")}
                        {...regField}
                    />
                </>: undefined}

            <BottomButtons>
                {values?.isAdmin ? <Button type="second" onClick={onDelete}>Delete</Button> : <div></div>}
                <div></div>
                <Button type="primary" onClick={formSubmitted}>Save</Button>
                <Button type="second" onClick={onCancel}>Cancel</Button>
            </BottomButtons>
        </fieldset>
    );
};

ProjectProject.defaultProps = {
    payments: {
        periods: [
            {label: "Monthly", value: "MONTHLY"},
            {label: "Quarterly", value: "QUARTERLY"},
            {label: "Half yearly", value: "HALF_YEARLY"},
            {label: "Yearly", value: "YEARLY"},
        ],
        types: [
            {label: "Place", value: "PLACE"},
            {label: "Project", value: "PROJECT"},
        ],
    }
};

export default ProjectProject;
