import * as React from 'react';
import {Routes, Route, BrowserRouter as Router} from 'react-router-dom';
import './App.css';
import Dashboard from './components/Layouts/Dashborad/Dashboard';
import Home from './containers/Home/Home';
import {useAuth} from 'react-oidc-context';
import {useEffect} from 'react';
import AfterLogin from './containers/AfterLogin/AfterLogin';
import Project from './containers/Project/Project';
import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import Projects from './containers/Projects/Projects';
import Settings from './containers/Settings/Settings';
import Places from './containers/Places/Places';
import Place from './containers/Place/Place';


function App() {
    const auth = useAuth();

    useEffect(() => {
         if (auth.isLoading === true || auth.isAuthenticated === true || auth.error !== undefined) {
             return;
         }
         else  {
             auth.signinRedirect();
         }
    }, [auth]);

    const httpLink = createHttpLink({
        uri: process.env.REACT_APP_GRAPHQL_URL,
        credentials: 'include'
    });

    const authLink = setContext(async (_, { headers }) =>  {
        const token = auth.user?.access_token;
        return {
            headers: {
                ...headers,
                authorization: `Bearer ${token}`
            }
        }
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.forEach(async error =>
            {
                // if (error.extensions.code === "AUTH_NOT_AUTHENTICATED") {
                //     await auth.signoutRedirect();
                // }
            });
        if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    const client = new ApolloClient({
        cache: new InMemoryCache(),
        link: authLink.concat(errorLink.concat(httpLink))
    });


    return (
        <>
            <Router>
                <Dashboard>
                    <ApolloProvider client={client}>
                        <Routes>
                            <Route path="/login" element={<AfterLogin />} />
                            <Route path="/projects/:projectId/:tab/:tabId" element={<Project />} />
                            <Route path="/projects/:projectId/:tab" element={<Project />} />
                            <Route path="/projects/:projectId" element={<Project />} />
                            <Route path="/projects" exact element={<Projects />} />
                            <Route path="/places/:placeId/:tab" element={<Place />} />
                            <Route path="/places/:placeId" element={<Place />} />
                            <Route path="/places" exact element={<Places />} />
                            <Route path="/settings" exact element={<Settings />} />
                            <Route path="/" exact element={<Home />} />
                        </Routes>
                    </ApolloProvider>
                </Dashboard>
            </Router>
        </>
    );
}

export default App;
