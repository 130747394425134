import {gql, useLazyQuery, useMutation, useQuery} from '@apollo/client';

export const PROJECT_FIELDS = gql`
    fragment ProjectFields on ProjectType {
        id
        name
        adminId
        contactName
        contactMail
        contactPhone
        country
        address
        companyName
        vat
        bank
        swift
        count
        paymentPeriod
        paymentObject
        sumPerObject
        active
        isAdmin
        isManager
    }
`;

export const PLACE_FIELDS = gql`
    fragment PlaceFields on PlaceType {
        id
        name
        address
        googleLink
        active
    }
`;

export const USER_FIELDS = gql`
    fragment UserFields on UserType {
        id:userId
        name
        email
        role
        places
        active
    }
`;

const GET_PROJECTS = gql`
    query Projects {
        projects {
            id
            name
            isManager
            places {
                id
                name
                address
                canAccess
                googleLink
            }
            users {
                id:userId
                name
            }
        }
    }`;

export const useProjects = () => useQuery(GET_PROJECTS);


const GET_PROJECT = gql`
    ${PROJECT_FIELDS}
    ${PLACE_FIELDS}
    ${USER_FIELDS}
    query Project($id: Int!) {
        projects (where: {id:{eq: $id}}) {
            ...ProjectFields
            places {
                ...PlaceFields
            }
            users {
                ...UserFields
            }
        }
    }`;

export const useProjectLazy = () => useLazyQuery(GET_PROJECT);


const SAVE_PROJECT = gql`
    ${PROJECT_FIELDS}
    mutation SaveCompany($input: ProjectInput!) {
        saveProject(input: $input) {
            ...ProjectFields
        }
    }`;

export const useSaveProject = () => useMutation(SAVE_PROJECT, {
    refetchQueries: [GET_PROJECTS, 'Projects'],
});

const DELETE_PROJECT = gql`
    mutation DeleteProject($id: Int!) {
        deleteProject(id: $id)
    }`;

export const useDeleteProject = () => useMutation(DELETE_PROJECT, {
    refetchQueries: [GET_PROJECTS, 'Projects'],
});


const GET_PLACES = gql`
    query Places($projectId: Int!) {
        places(projectId: $projectId) {
            id
            name
            address
        }
    }`;

export const usePlaces = (variables) => useQuery(GET_PLACES, {variables});

const GET_PLACE = gql`
    ${PLACE_FIELDS}
    query Place($projectId: Int!, $id: Int!) {
        places(projectId: $projectId, where: {id: {eq: $id}}) {
            ...PlaceFields
            __typename
        }

        projects {
            id
            name
            __typename
        }
    }`;

export const usePlaceLazy = () => useLazyQuery(GET_PLACE);

const SAVE_PLACE = gql`
    ${PLACE_FIELDS}
    mutation SavePlace($input: PlaceInput!) {
        savePlace(input: $input) {
            ...PlaceFields
        }
    }`;

export const useSavePlace = () => useMutation(SAVE_PLACE, {
    refetchQueries: [GET_PROJECT, 'Project'],
});


const GET_PLACE_INFO = gql`
    ${PLACE_FIELDS}
    query PlaceInfo($placeId: Int!) {
        place(placeId: $placeId) {
            ...PlaceFields
        }
    }`;

export const usePlace = (variables) => useQuery(GET_PLACE_INFO, {variables});


const DELETE_PLACE = gql`
    mutation DeletePlace($id: Int!) {
        deletePlace(id: $id)
    }`;

export const useDeletePlace = (variables) => useMutation(DELETE_PLACE, {
    refetchQueries: [GET_PROJECT, 'Project'],
});


const GET_USERS = gql`
    ${USER_FIELDS}
    query Users($projectId: Int!) {
        users(projectId: $projectId) {
            ...UserFields
        }
    }`;

export const useUsers = (variables) => useQuery(GET_USERS, {variables});


const GET_USER = gql`
    ${USER_FIELDS}
    query Users($projectId: Int!, $id: String!) {
        users(projectId: $projectId, where: {userId: {eq: $id}}) {
            ...UserFields
        }
    }`;

export const useUserLazy = () => useLazyQuery(GET_USER);

const SAVE_USER = gql`
    ${USER_FIELDS}
    mutation SaveUser($input: UserInput!) {
        saveUser(input: $input) {
            ...UserFields
        }
    }`;

export const useSaveUser = () => useMutation(SAVE_USER);

const DELETE_USER = gql`
    mutation DeleteUser($input: DeleteProjectUserInput!) {
        deleteUser(input: $input)
    }`;

export const useDeleteUser = () => useMutation(DELETE_USER);


const GET_QRS = gql`
    query Qrs($placeId: Int!) {
        qrs (placeId: $placeId) {
            id
            code
            locale
            placeId
            fileName
        }
    }`;
export const useQrsLazy = () => useLazyQuery(GET_QRS);



const ADD_TRANSLATE = gql`
    mutation AddTranslate($input: TranslateInput!) {
        addTranslate(input: $input)
    }`;

export const useAddTranslate = () => useMutation(ADD_TRANSLATE);



