import React from 'react';
import classes from './Tutorial.module.scss';

const Tutorial = (props) => {

    if (!props.show) {
        return (<></>);
    }

    return (
        <div className={classes.overlay}>
            <div className={classes.tutorial}>
                {props.children}
            </div>
        </div>
    );
};

Tutorial.defaultProps = {
    show: false
};

export default Tutorial;
