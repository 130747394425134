import React, {useState, useEffect} from 'react';
import Form from '../../components/UI/Form/Form';
import {useTranslation} from 'react-i18next';
import Page from '../../components/UI/Page/Page';
import {useNavigate, useParams} from 'react-router-dom';
import {navigation} from '../../components/Layouts/Dashborad/Navigation';
import {useProjectLazy} from '../../apollo/client.queries';
import {SwitchComponents} from '../../components/System/SwitchComponents/SwitchComponents';
import ProjectProject from './Tabs/ProjectProject';
import ProjectPlaces from './Tabs/ProjectPlaces';
import ProjectUsers from './Tabs/ProjectUsers';
import classes from './Project.module.scss';
import Button from '../../components/UI/Button/Button';
import Tutorial from '../../components/UI/Tutorial/Tutorial';
import ProjectPayments from './Tabs/ProjectPayments';

const Project = (props) => {

    const {t} = useTranslation();
    const params = useParams();
    const navigate = useNavigate();

    const title = t("Project");

    const [values, setValues] = useState({active: true});
    const [tutorial, setTutorial] = useState(false);

    const canAccess = !values.isManager && params.projectId !== "new";

    const [getProjectLazy, projectData] = useProjectLazy();

    useEffect(() => {
        if (!params.projectId || params.projectId === "new") return;
        getProjectLazy({variables: {id: +params.projectId}});
    }, [getProjectLazy, params.projectId]);

    useEffect(() => {
        if (projectData.data && projectData.data?.projects?.length > 0) {
            const company = projectData.data.projects[0];
            setValues(company);

            if (company.places.length === 0 && params.tab !== "places") {
                setTutorial(true);
            }
        }
    }, [projectData.data]);


    if (projectData.loading) {
        return (
            <Page title={title}>
                <Form>
                    <div>Loading</div>
                </Form>
            </Page>
        );
    }

    if (projectData.error) {
        return (
            <Page title={title}>
                <Form>
                    <div>Error</div>
                </Form>
            </Page>
        );
    }

    if (projectData.data?.projects?.length === 0) {
        return (
            <Page title={title}>
                <Form>
                    <div>No data found.</div>
                </Form>
            </Page>
        );
    }

    const submenu = () => {
        return values.id !== undefined ? navigation(values.id).company : navigation('new').company.slice(0, 1);
    }

    const getTitle = () => {
        return `${title} ${values.name ?? ""}`;
    }

    const tutorialGoToPlace = () => {
        setTutorial(false)
        navigate(`/projects/${params.projectId}/places/new`);
    }

    if (canAccess) {
        return (
            <Page title={getTitle()}>
                <div className={classes.empty}>{t("Access denied.")}</div>
            </Page>
        );
    }

    return (
        <>
            <Page title={getTitle()} submenu={submenu()}>
                <SwitchComponents active={params.tab ?? ""}>
                    <Form  name="">
                        <ProjectProject data={{values, setValues}} />
                    </Form>
                    <ProjectPlaces data={projectData.data} refetch={projectData.refetch} name="places" />
                    <ProjectUsers data={projectData.data} refetch={projectData.refetch} name="users" />
                    <ProjectPayments data={projectData.data} refetch={projectData.refetch} name="payments" />
                </SwitchComponents>
            </Page>

            <Tutorial show={tutorial}>
                That’s it!<br/><br/>

                Now we need create a place.<br/><br/>

                <div>
                    <Button type="primary" onClick={tutorialGoToPlace}>{t("Create place")}</Button>
                    <Button type="second" onClick={tutorialGoToPlace}>{t("Skip tutorial")}</Button>
                </div>
            </Tutorial>
        </>
    );
};

Project.defaultProps = {};

export default Project;
