import React from 'react';
import OneColumn from '../../../components/UI/Form/OneColumn/OneColumn';
import {useTranslation} from 'react-i18next';
import {MdOutlineSms, MdOutlineMail, MdQrCode2 } from "react-icons/md";
import {Link, useNavigate, useParams} from 'react-router-dom';
import classes from './PlaceStart.module.scss';

const PlaceStart = (props) => {

    const params = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const onClick = (url) => {
        navigate(url);
    }

    return (
        <OneColumn title={t("Select action")}>
            <ul className={classes.actions}>
                <li onClick={() => onClick(`/places/${params.placeId}/sms`)}>
                    <MdOutlineSms />
                    <div>
                        {t("SMS")}
                    </div>
                </li>
                <li onClick={() => onClick(`/places/${params.placeId}/email`)}>
                    <MdOutlineMail />
                    <div>
                        {t("E-mail")}
                    </div>
                </li>
                <li onClick={() => onClick(`/places/${params.placeId}/qr`)}>
                    <MdQrCode2 />
                    <div>
                        {t("QR code")}
                    </div>
                </li>
            </ul>
        </OneColumn>
    );
};

PlaceStart.defaultProps = {};

export default PlaceStart;
