import React from 'react';
import classes from './DateTime.module.scss';
import cn from 'classnames';
import {format, parseISO} from 'date-fns';

const DateTime = (props) => {
    const {data, showTime = true} = props;
    const date = parseISO(data);

    if (date === "Invalid Date") return (
        <div className={cn(classes.date, props.className)}>
            <span> </span>
            {showTime && <span className={classes.time}> </span>}
        </div>
    );

    return (
        <div className={cn(classes.date, props.className)}>
            <span>{format(date,'dd.MM.Y')}</span>
            {showTime && <span className={classes.time}>{format(date,'HH:mm')}</span>}
        </div>
    );
};

export default DateTime;
