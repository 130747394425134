import React from 'react';
import classes from './Panel.module.scss';
import cn from 'classnames';

const Panel = (props) => {
    const {actions, filters, search, className} = props;

    return (
        <div className={cn(className, classes.panel)}>
            <div>{actions ? actions() : undefined}</div>
            <div>{filters ? filters() : undefined}</div>
            <div>{search ? search() : undefined}</div>
        </div>
    );
};

Panel.defaultProps = {};

export default Panel;
