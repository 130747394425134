import React, {useEffect, useState} from 'react';
import { useTable, useRowSelect } from 'react-table';
import {useTranslation} from "react-i18next";
// import Empty from '../Empty/Empty';
import classes from './Table.module.scss';
import cn from 'classnames';


const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)

const Table = ({className, columns, data, center, header, onAddClick, onRowClick, onRowCheck, onSelectClick, empty})  => {
    const { t } = useTranslation();

    const selectionHook = (hooks) => {
        if (onSelectClick !== undefined) {
            hooks.visibleColumns.push(columns => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({getToggleAllRowsSelectedProps}) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        </div>
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({row}) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ]);
        }
    }

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, selectedFlatRows, state: { selectedRowIds } } = useTable({ columns, data },
        useRowSelect, hooks => selectionHook(hooks));


    useEffect(() => {
        if (onSelectClick === undefined)
            return;

        onSelectClick(selectedFlatRows.map(
            d => d.original
        ));

    }, [selectedRowIds]);


    if (rows.length == 0 && empty) return (<div>{t("No data to display.")}</div>);

    const getColumnsCount = () => {
        return onSelectClick !== undefined
            ? headerGroups[0].headers.length + 1
            : headerGroups[0].headers.length
    }

    return (
        <div className={cn(className, classes.scroll)}>
            <table className={classes.Table} {...getTableProps()} >
                {header && <thead className={classes.header}>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, i) => (
                                <th className={cn(column.className, {[classes.center]:center && i > 1})} {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>}
                <tbody className={classes.tbody} {...getTableBodyProps()}>

                {rows.map((row, index) => {
                    prepareRow(row);

                    return (
                        <tr onClick={() => onRowClick(row.original)} {...onRowCheck(row.original)}  {...row.getRowProps()}>
                            {row.cells.map((cell, i) => {
                                return <td {...cell.getCellProps({
                                    className: cn(cell.column.className, {[classes.center]:center && i > 1})
                                })}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}

                {rows.length == 0 && <tr><td colSpan={getColumnsCount()}> {t("No data to display.")}</td></tr>

                }

                </tbody>
            </table>
        </div>
    );
}
Table.defaultProps = {
    onRowClick: () => {},
    onRowCheck: () => {},
    className: {},
    empty: false,
    header: true
}
export default Table;
