import React from 'react';
import {useTranslation} from 'react-i18next';
import Page from '../../components/UI/Page/Page';
import {useAuth} from 'react-oidc-context';
import classes from './Settings.module.scss';

const Settings = (props) => {

    const auth = useAuth();
    const {t} = useTranslation();
    const title = t("Settings");

    const token = auth.user?.access_token;

    return (
        <>
            <Page title={title}>
                <div className={classes.token}>
                    {token}
                </div>
            </Page>
        </>
    );
};

Settings.defaultProps = {};

export default Settings;
