import React from 'react';
import ReactDOM from 'react-dom';
import { AuthProvider, useAuth } from "react-oidc-context";
import App from './App';
import './i18n';


const oidcConfig = {
    authority: process.env.REACT_APP_AUTHORITY,
    client_id: 'js',
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    response_type: "code",
    response_mode: "fragment",
    scope: "openid profile scope2",
    post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,

};

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
        <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
