import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';


const Home = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(`/places`);
    }, []);

    return (
        <div>
            Loading...
        </div>
    );
};

Home.defaultProps = {};

export default Home;
