import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {usePlace} from '../../apollo/client.queries';
import Page from '../../components/UI/Page/Page';
import Form from '../../components/UI/Form/Form';
import {navigation} from '../../components/Layouts/Dashborad/Navigation';
import {SwitchComponents} from '../../components/System/SwitchComponents/SwitchComponents';
import PlaceSms from './Tabs/PlaceSms';
import PlaceEmail from './Tabs/PlaceEmail';
import PlaceQr from './Tabs/PlaceQr';
import PlaceEdit from './Tabs/PlaceEdit';
import PlaceStart from './Tabs/PlaceStart';
import PlaceReview from './Tabs/PlaceReview';

const Place = (props) => {

    const {t} = useTranslation();
    const params = useParams();

    const [title, setTitle] = useState("");

    const {loading, error, data} = usePlace({placeId: +params.placeId})

    useEffect(() => {
        if (!data) {
            return;
        }

        const {name} = data.place;
        setTitle(name);
    }, [data]);




    if (loading) {
        return (
            <Page title={title}>
                <Form>
                    <div>Loading</div>
                </Form>
            </Page>
        );
    }

    if (error) {
        return (
            <Page title={title}>
                <Form>
                    <div>Error</div>
                </Form>
            </Page>
        );
    }

    if (!data.place) {
        return (
            <Page title={title}>
                <Form>
                    <div>No data found.</div>
                </Form>
            </Page>
        );
    }

    return (
        <>
            <Page title={title} submenu={navigation(params.placeId).place}>
                <SwitchComponents active={params.tab ?? ""}>
                    <PlaceStart name="" />
                    <PlaceReview name="review" />
                    <Form  name="sms">
                        <PlaceSms place={title} />
                    </Form>
                    <Form  name="email">
                        <PlaceEmail place={title} />
                    </Form>
                    <PlaceQr name="qr" place={title} />
                    <PlaceEdit name="edit" />
                </SwitchComponents>
            </Page>
        </>
    );
};

Place.defaultProps = {};

export default Place;
