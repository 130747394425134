import React from 'react';
import classes from './Input.module.scss';
import {useTranslation} from "react-i18next";
import {haveDefaultValue, haveError} from '../../../utils/form';

const Input = (props) => {
  const {t} = useTranslation();
  const inputType = props.type || 'text';
  const cls = ['form-field', classes.Input];
  const htmlFor = props.id || `${inputType}-${Math.random()}`;

  const error = haveError(props);
  const defaultValue = haveDefaultValue(props);

  if (error) {
    cls.push(classes.invalid)
  }

  return (
    <div className={cls.join(' ')} style={props.style}>

      { (props.label || defaultValue) ?  <label htmlFor={htmlFor}>{props.label || props.placeholder}</label> : undefined }

      <input
        name={props.name}
        type={props.type}
        id={htmlFor}
        min={props.min}
        max={props.max}
        step={props.step}
        defaultValue={defaultValue}
        onChange={props.onChange}
        disabled={props.disabled}
        placeholder={props.placeholder}
      />

      { error && <span>{error || t('Required')}</span> }

    </div>
  )
}

Input.defaultProps = {
  disabled: false,
  type: "text"
}

export default Input
