import React from 'react';
import classes from './Page.module.scss';
import Submenu from '../../Layouts/Dashborad/Submenu/Submenu';


const Page = (props) => {
    const {title, children, submenu} = props;

    return (
        <div className={classes.page}>
            <div className={classes.header}>
                <div className={classes.list}>
                    <div></div>
                    <h1>{title}</h1>
                    <div></div>
                    <Submenu data={submenu} />
                </div>
            </div>
            <div className={classes.body}>
                <div className={classes.list}>
                    {children}
                </div>
            </div>
        </div>
    );
};

Page.defaultProps = {};

export default Page;
