import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import OneColumn from '../../../components/UI/Form/OneColumn/OneColumn';
import {useTranslation} from 'react-i18next';
import classes from '../../Projects/Projects.module.scss';
import {BsArrowRightShort} from 'react-icons/bs';
import Table from '../../../components/UI/Table/Table';
import Panel from '../../../components/UI/Panel/Panel';
import Button from '../../../components/UI/Button/Button';
import ProjectUsersForm from './ProjectUsersForm';

const ProjectUsers = (props) => {
    const {data, refetch} = props;

    const params = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [userList, setUserList] = useState([]);

    useEffect(() => {
        setUserList(data.projects[0].users);
    }, [data.projects]);

    if (params.tabId) {
        return (
            <ProjectUsersForm data={data} refetch={refetch} />
        )
    }

    if (!data?.projects || data.projects.length === 0) {
        return (
            <div>No data found.</div>
        );
    }

    const onRowClick = (data) => {
        const {id} = data;
        navigate(`/projects/${params.projectId}/users/${id}`);
    }

    const onReviewClick = (data) => {
        const {id} = data;
        navigate(`/places/${id}`);
    }

    const actions = () => {
        return (
            <Button type="primary" onClick={onAddPlace}>{t("Add user")}</Button>
        );
    }

    const onAddPlace = () => {
        navigate(`/projects/${params.projectId}/users/new`);
    }

    return (
        <OneColumn title={t("Users")} className={classes.users}>
            <Panel actions={actions} className={classes.panel} />
            <Table
                header={false}
                data={userList}
                onRowClick={() => {}}
                columns={[
                    {
                        Header: t('User'),
                        accessor: 'name',
                        Cell: (cellData) => <div style={{cursor: 'pointer'}} onClick={() => onRowClick(cellData.row.original)}>
                            <div className={classes.tableTitle}>{t("User")}</div>
                            <div className={classes.tableName}>{cellData.value}</div>
                            <div className={classes.tableAction}>
                                {t("Edit user")} <BsArrowRightShort />
                            </div>
                        </div>
                    },{
                        Header: t('Rights'),
                        accessor: 'role',
                        Cell: (cellData) => <div style={{cursor: 'pointer'}} onClick={() => onRowClick(cellData.row.original)}>
                            <div className={classes.tableTitle}>{t("Role")}</div>
                            <div className={classes.tableName}>{t(cellData.value)}</div>
                            <div className={classes.tableAction}>&nbsp;</div>
                        </div>
                    }
                ]}
            />

        </OneColumn>
    );
};

ProjectUsers.defaultProps = {};

export default ProjectUsers;




