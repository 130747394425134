import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import OneColumn from '../../../components/UI/Form/OneColumn/OneColumn';
import Button from '../../../components/UI/Button/Button';
import classes from './PlaceQr.module.scss';
import cn from 'classnames';
import {qrData} from '../Settings';
import {useQrsLazy} from '../../../apollo/client.queries';
import {useParams} from 'react-router-dom';
import {useAuth} from 'react-oidc-context';
import Spinner from '../../../components/UI/Spinner/Spinner';

const PlaceQr = (props) => {
    const {place} = props;

    const url = process.env.REACT_APP_DOWNLOAD_URL;

    const {t} = useTranslation();
    const auth = useAuth();
    const params = useParams();
    const [categories, setCategories] = useState(qrData());
    const [selected, setSelected] = useState([]);
    const [loading, setLoading] = useState(false);

    const [getQrsLazy, getQrsData] = useQrsLazy();

    useEffect(() => {
        getQrsLazy({variables: {placeId: +params.placeId}}).then();
    }, []);

    useEffect(() => {
        if (!getQrsData.data) {
            return;
        }

        const _categories = [...categories];
        _categories[0].items = getQrsData.data.qrs.map(x => (
            {id: x.id, image: `${url}/image?id=${x.code}`, name: 'QR code ' + x.locale, className: classes.qr, code: x.code, selected: false}));
        setCategories(_categories);
    }, [getQrsData.data]);

    const onSelect = (item) => {
        selected.includes(item.code)
            ? setSelected(selected.filter(x => x !== item.code))
            : setSelected([...selected, item.code]);
    }

    const onDownload = () => {
        const downloadUrl = `${url}/printKit`;
        const token = auth.user?.access_token;

        setLoading(true);

        fetch(downloadUrl, {
            method: 'post',
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
                placeId: +params.placeId,
                files: selected
            }),
        }).
            then((res) => {
                if (res.status !== 200) {
                    alert("Error. Please contact with support@loyaty.eu");
                    throw new Error();
                }

                return res.blob();
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${place}-${Date.now()}.zip`;
                document.body.appendChild(a);
                a.click();
                a.remove();
            })
            .then(() => {
                setLoading(false);
                setSelected([]);
            }).catch(console.error);
    }

    return (
        <>
            {categories.map(group =>
                <OneColumn key={group.id} title={t(group.title)}>
                    <ul className={classes.grid}>
                        {group.items.map(item => (
                            <li key={item.id}
                                className={cn({[classes.selected]:selected.includes(item.code)})}
                                onClick={() => onSelect(item)}>
                                <span className={classes.card}>
                                    {item.image ? <img src={item.image} className={item.className} alt={item.name} /> : undefined}
                                    <div>{item.name}</div>
                                </span>
                            </li>
                        ))}
                    </ul>
                </OneColumn>
            )}

            <div className={cn(classes.download, {[classes.hide]:selected.length === 0})}>
                <div>
                    {loading ? t("Downloading") : t("Selected")} {selected.length} {t("items")}.
                    <Button disabled={loading} type="primary" onClick={onDownload}>
                        {loading ? <Spinner /> : t("Download")}</Button>
                </div>
            </div>
        </>
    );
};

PlaceQr.defaultProps = {};

export default PlaceQr;
