import React from 'react';
import classes from './Menu.module.scss';
import {useAuth} from 'react-oidc-context';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const Menu = (props) => {

    const auth = useAuth();
    const {t} = useTranslation();

    const logout = () => {
        auth.signoutRedirect();
    }

    return (
        <div className={classes.menu}>
            <div className={classes.nav}>
                <ul>
                    <li><Link to="/places">{t("Places")}</Link></li>
                    <li><Link to="/projects">{t("Projects")}</Link></li>
                    <li><Link to="/settings">{t("Settings")}</Link></li>
                </ul>
            </div>
            <div className={classes.user}>
                <p>{auth.user?.profile.sub}</p>
                <button onClick={logout}>Log out</button>
            </div>
        </div>
    );
};

Menu.defaultProps = {};

export default Menu;
