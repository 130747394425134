import {gql, useLazyQuery, useMutation, useQuery} from '@apollo/client';

const GET_SMS = gql`
    query Sms($placeId: Int!) {
        sms(placeId: $placeId, order: {sendAt: DESC}) {
            sendAt
            phone
            text
        }
    }`;

export const useSmsLazy = (variables) => useLazyQuery(GET_SMS, {variables});

const SEND_SMS = gql`
    mutation SendSms($input: SmsInput!) {
        sendSms(input: $input)
    }`;

export const useSendSms = () => useMutation(SEND_SMS);



const GET_EMAILS = gql`
    query Emails($placeId: Int!) {
        emails(placeId: $placeId, order: {sendAt: DESC}) {
            sendAt
            mail
            text
        }
    }`;

export const useEmailsLazy = (variables) => useLazyQuery(GET_EMAILS, {variables});

const SEND_EMAIL = gql`
    mutation SendEmail($input: EmailInput!) {
        sendEmail(input: $input)
    }`;

export const useSendEmail = () => useMutation(SEND_EMAIL);


const GET_REVIEWS = gql`
    query Reviews($placeId: Int!) {
        reviews(placeId: $placeId, order: {reviewedAt: DESC}) {
            reviewedAt
            locale
            productQuality
            serviceQuality
            reviewText
            name
            phone
            email
            code
            serviceType
        }
    }`;

export const useReviews = (variables) => useQuery(GET_REVIEWS, {variables});


