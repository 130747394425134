import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import ProjectPlaceForm from './ProjectPlacesForm';
import OneColumn from '../../../components/UI/Form/OneColumn/OneColumn';
import {useTranslation} from 'react-i18next';
import classes from '../../Projects/Projects.module.scss';
import {BsArrowRightShort} from 'react-icons/bs';
import Table from '../../../components/UI/Table/Table';
import Panel from '../../../components/UI/Panel/Panel';
import Button from '../../../components/UI/Button/Button';

const ProjectPlaces = (props) => {
    const {data, refetch} = props;

    const params = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();

    if (params.tabId) {
        return (
            <ProjectPlaceForm refetch={refetch} />
        )
    }

    if (!data?.projects || data.projects.length === 0) {
        return (
            <div>No data found.</div>
        );
    }

    const onRowClick = (data) => {
        const {id} = data;
        navigate(`/projects/${params.projectId}/places/${id}`);
    }

    const onReviewClick = (data) => {
        const {id} = data;
        navigate(`/places/${id}`);
    }

    const actions = () => {
        return (
            <Button type="primary" onClick={onAddPlace}>{t("Add place")}</Button>
        );
    }

    const onAddPlace = () => {
        navigate(`/projects/${params.projectId}/places/new`);
    }

    return (
        <OneColumn title={t("Places")}>
            <Panel actions={actions} className={classes.panel} />
            <Table
                header={false}
                data={data.projects[0].places}
                onRowClick={() => {}}
                columns={[
                    {
                        Header: t('Place'),
                        accessor: 'name',
                        Cell: (cellData) => <div style={{cursor: 'pointer'}} onClick={() => onRowClick(cellData.row.original)}>
                            <div className={classes.tableTitle}>{t("Place")}</div>
                            <div className={classes.tableName}>{cellData.value}</div>
                            <div className={classes.tableAction}>
                                {t("Edit place")} <BsArrowRightShort />
                            </div>
                        </div>
                    },{
                        Header: t('Address'),
                        accessor: 'address',
                        Cell: (cellData) => <div style={{cursor: 'pointer'}} onClick={() => onRowClick(cellData.row.original)}>
                            <div className={classes.tableTitle}>{t("Address")}</div>
                            <div className={classes.tableName}>{cellData.value}</div>
                            <div className={classes.tableAction}>&nbsp;</div>
                        </div>
                    }, {
                        Header: t('Reviews'),
                        accessor: 'reviews',
                        Cell: (cellData) => <div style={{cursor: 'pointer'}} onClick={() => onReviewClick(cellData.row.original)}>
                            <div className={classes.tableTitle}>{t("Reviews")}</div>
                            <div className={classes.tableName}>0</div>
                            <div className={classes.tableAction}>
                                {t("View reviews")} <BsArrowRightShort />
                            </div>
                        </div>
                    },
                ]}
            />

        </OneColumn>
    );
};

ProjectPlaces.defaultProps = {};

export default ProjectPlaces;
