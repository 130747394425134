import React, {useEffect, useState} from 'react';
import Input from '../../../components/UI/Input/Input';
import BottomButtons from '../../../components/UI/Form/BottomButtons/BottomButtons';
import Button from '../../../components/UI/Button/Button';
import {useTranslation} from 'react-i18next';
import {notHaveErrors, validateFields, validateForm} from '../../../utils/form';
import * as yup from 'yup';
import {useNavigate, useParams} from 'react-router-dom';
import TwoColumns from '../../../components/UI/Form/TwoColumns/TwoColumns';
import Textarea from '../../../components/UI/Textarea/Textarea';
import {ClientLanguages, ClientSms} from '../Settings';
import Select from '../../../components/UI/Select/Select';
import {useSendSms, useSmsLazy} from '../../../apollo/review.queries';
import Table from '../../../components/UI/Table/Table';
import DateTime from '../../../components/UI/Table/DateTime/DateTime';
import Toast, {toastMessage} from '../../../components/UI/Toast/Toast';
import '../../../utils/prototype.js'


const PlaceSms = (props) => {
    const {place} = props;

    const {t} = useTranslation();
    const params = useParams();
    const navigate = useNavigate();

    const translates = ClientSms();
    const link = `${process.env.REACT_APP_REVIEW_URL}/{{link}}`;

    const [values, setValues] = useState({
        clientPhone: 371,
        clientLocale: localStorage.getItem("i18next_language") ?? 'lv'
    });

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const [getSmsLazy, getSmsData] = useSmsLazy();
    const [sendSms] = useSendSms();


    useEffect(() => {
        if (!place) {
            return;
        }

        getSmsLazy({variables: {placeId: +params.placeId}}).then();

    }, [place]);

    useEffect(() => {
        if (!values.clientLocale) {
            return;
        }

        const translate = translates.find(x => x.locale === values.clientLocale);
        const newValues = {
            ...values,
            clientText: values.clientName?.length > 0
                ? translate.withName.format(values.clientName, place, link)
                : translate.withoutName.format(place, link)
        };
        setValues(newValues);

    }, [place, values.clientLocale]);



    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        if (event.target.name === "clientName") {
            const translate = translates.find(x => x.locale === values.clientLocale);
            newValues["clientText"] = event.target.value.length > 0
                ? translate.withName.format(event.target.value, place, link)
                : translate.withoutName.format(place, link);
        }

        setValues(newValues);
    }

    const validationRules = {
        clientLocale: yup.string().required(t("Require")),
        clientPhone: yup.string().required(t("Require"))
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(8, 'Minimum 8 digits'),
        clientText: yup.string().required(t("Require"))
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const formSubmitted = (e) => {
        e.preventDefault();
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors))
                saveData();
            else
                setErrors(errors);
        });
    }

    const saveData = () => {
        setLoading(true);

        const variables = {
            input: {
                placeId: +params.placeId,
                clientName: values.clientName,
                clientLocale: values.clientLocale.toUpperCase(),
                clientPhone: values.clientPhone,
                clientText: values.clientText
            }
        };

        sendSms({variables: variables}).then(result => {
            toastMessage(true, t("Successfully sent!"));
            setLoading(false);
            getSmsData.refetch();
        }).catch(() => {
            toastMessage(false, t("Sms not sent."));
            setLoading(false);
        });
    }

    const onCancel = () => {
        navigate(`/places/${params.placeId}`);
    }

    return (
        <>
            <TwoColumns title={t("Send SMS")}>
                <Input
                    name="clientName"
                    label={t("Client name")}
                    {...regField}
                />

                <Select
                    name="clientLocale"
                    label={t("Client language")}
                    options={ClientLanguages()}
                    {...regField}
                />

                <Input
                    name="clientPhone"
                    type="number"
                    label={t("Client phone")}
                    {...regField}
                />
            </TwoColumns>

            <Textarea
                name="clientText"
                label={`${t("Text")} ${values.clientText?.length ?? 0}/160`}
                height={120}
                max={160}
                {...regField}
            />

            <BottomButtons>
                <div></div>
                <div></div>
                <Button type="primary" disabled={loading} onClick={formSubmitted}>Save</Button>
                <Button type="second" onClick={onCancel}>Cancel</Button>
            </BottomButtons>

            <br/>

            <h3>Last send emails</h3>

            <Table
                data={getSmsData.data?.sms ?? []}
                onRowClick={() => {}}
                columns={[
                    {
                        Header: t('Date'),
                        accessor: 'sendAt',
                        Cell: (cellData) => <DateTime data={cellData.value} />
                    },{
                        Header: t('Phone'),
                        accessor: 'phone',
                    }, {
                        Header: t('Text'),
                        accessor: 'text',
                    }
                ]}
            />

            <Toast />
        </>
    );
};

PlaceSms.defaultProps = {};

export default PlaceSms;
