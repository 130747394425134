import React from 'react'
import classes from './Button.module.scss'

const Button = props => {
    const cls = [
        classes.Button,
        classes[props.type],
        props.className
    ]

    const inputProps = {}
    if (props.submit)
        inputProps.type = "submit"

    return (
        <button
            onClick={props.onClick}
            className={cls.join(' ')}
            disabled={props.disabled}
            style={props.style}
            form={props.form}
            {...inputProps}
        >
            {props.children}
        </button>
    )
}

export const ButtonLink = props => {
    const cls = [
        classes.Button,
        classes[props.type],
        props.className
    ]

    return (
        <a
            href={props.href}
            target={props.target}
            className={cls.join(' ')}
            disabled={props.disabled}
            style={props.style}
            form={props.form}
        >
            {props.children}
        </a>
    )
}

Button.defaultProps = {
    submit: false
};


export default Button
