import React from 'react'
import toast, { Toaster } from 'react-hot-toast';

export const toastMessage = (type, message) => {
    if (type === true) return toast.success(message);
    if (type === false) return toast.error(message);
}

const Toast = (props) => {
    return (
        <Toaster
            position="bottom-center"
            reverseOrder={false}
            containerStyle={{
                bottom: 100
            }}
            toastOptions={{
                duration: 5000,
            }}
        />
    );
};

export default Toast;
