export const navigation = (id) => ({
    company: [
        {label: "Project", url: `/projects/${id}`},
        {label: "Places", url: `/projects/${id}/places`},
        {label: "Users", url: `/projects/${id}/users`},
        {label: "Payments", url: `/projects/${id}/payments`}
    ],
    place: [
        {label: "Place", url: `/places/${id}`},
        {label: "Reviews", url: `/places/${id}/review`},
    ]
});
