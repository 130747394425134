import React from 'react';

const PlaceEdit = (props) => {
    return (
        <div>PlaceEdit</div>
    );
};

PlaceEdit.defaultProps = {};

export default PlaceEdit;
