import React from 'react';
import classes from './Submenu.module.scss'
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const Submenu = (props) => {
    const {data} = props;

    const {t} = useTranslation();

    if (!data || data?.length === 0)
        return (<></>);

    return (
        <ul className={classes.Submenu}>
            {data.map(x => <li key={x.url}>
                {x.url !== "new" ? <Link to={x.url}>{t(x.label)}</Link> : <span>t(x.label)}</span>}
                </li>)}
        </ul>
    );
};

Submenu.defaultProps = {};

export default Submenu;
