import React, {useState} from 'react';
import OneColumn from 'components/UI/Form/OneColumn/OneColumn';
import {useTranslation} from 'react-i18next';

const ProjectPayments = (props) => {
    const {t} = useTranslation();

    // const {values, setValues} = props.data;
    // const [errors, setErrors] = useState({});
    //
    // const canAccess = !values.isAdmin;

    return (

        <OneColumn title={t("Payments")}>
            {/*<Table*/}
            {/*    header={false}*/}
            {/*    data={data.projects[0].places}*/}
            {/*    onRowClick={() => {}}*/}
            {/*    columns={[*/}
            {/*        {*/}
            {/*            Header: t('Place'),*/}
            {/*            accessor: 'name',*/}
            {/*            Cell: (cellData) => <div style={{cursor: 'pointer'}} onClick={() => onRowClick(cellData.row.original)}>*/}
            {/*                <div className={classes.tableTitle}>{t("Place")}</div>*/}
            {/*                <div className={classes.tableName}>{cellData.value}</div>*/}
            {/*                <div className={classes.tableAction}>*/}
            {/*                    {t("Edit place")} <BsArrowRightShort />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        },{*/}
            {/*            Header: t('Address'),*/}
            {/*            accessor: 'address',*/}
            {/*            Cell: (cellData) => <div style={{cursor: 'pointer'}} onClick={() => onRowClick(cellData.row.original)}>*/}
            {/*                <div className={classes.tableTitle}>{t("Address")}</div>*/}
            {/*                <div className={classes.tableName}>{cellData.value}</div>*/}
            {/*                <div className={classes.tableAction}>&nbsp;</div>*/}
            {/*            </div>*/}
            {/*        }, {*/}
            {/*            Header: t('Reviews'),*/}
            {/*            accessor: 'reviews',*/}
            {/*            Cell: (cellData) => <div style={{cursor: 'pointer'}} onClick={() => onReviewClick(cellData.row.original)}>*/}
            {/*                <div className={classes.tableTitle}>{t("Reviews")}</div>*/}
            {/*                <div className={classes.tableName}>0</div>*/}
            {/*                <div className={classes.tableAction}>*/}
            {/*                    {t("View reviews")} <BsArrowRightShort />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        },*/}
            {/*    ]}*/}
            {/*/>*/}

        </OneColumn>
    );
};

ProjectPayments.defaultProps = {};

export default ProjectPayments;
