import React from 'react';
import classes from './BottomButtons.module.scss';

const BottomButtons = (props) => {
    const {children} = props;

    return (
        <div className={classes.BottomButtons}>
            {children}
        </div>
    );
};

BottomButtons.defaultProps = {};

export default BottomButtons;
