import React from 'react';
import classes from './Form.module.scss';

const Form = (props) => {
    const {children} = props;
    return (
        <div className={classes.form}>
            <div className={classes.body}>
                {children}
            </div>
        </div>
    );
};

Form.defaultProps = {};

export default Form;
