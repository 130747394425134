export const ClientLanguages = () => [
    {label: "Latviešu", value: "lv"},
    {label: "Русский", value: "ru"},
    {label: "English", value: "en"}
]

export const ClientSms = () => [
    {
        locale: "lv",
        withName: "Sveiki, %s! Paldies, ka izvelejaties %s. Ludzu, novertejiet mus: %s",
        withoutName: "Paldies, ka izvelejaties %s. Ludzu, novertejiet mus: %s"
    },
    {
        locale: "en",
        withName: "Hi, %s! Thank you for choosing %s. Please, rate us: %s",
        withoutName: "Thank you for choosing %s. Please, rate us: %s"
    },
    {
        locale: "ru",
        withName: "Привет, %s! Спасибо, что выбрали %s. Пожалуйста, оцените нас: %s",
        withoutName: "Спасибо, что выбрали %s. Пожалуйста, оцените нас: %s"
    },
];


export const ClientEmail = () => [
    {
        locale: "lv",
        title: "⭐⭐⭐⭐⭐ Lūdzu, novērtējiet %s",
        withName: "Sveiki, %s! Paldies, ka izvēlējāties %s 👍. Lūdzu, novērtējiet mūsu pakalpojumus. Izmantojiet zemāk esošo pogu, nospiediet piecas zvaigznes un uzrakstiet dažus vārdus.",
        withoutName: "Paldies, ka izvēlējāties %s 👍. Lūdzu, novērtējiet mūsu pakalpojumus. Izmantojiet zemāk esošo pogu, nospiediet piecas zvaigznes un uzrakstiet dažus vārdus."
    },
    {
        locale: "en",
        title: "⭐⭐⭐⭐⭐ Please rate %s",
        withName: "Hi, %s! Thank you for choosing %s 👍. Please, rate our services. Use the button below, press five stars and write few words.",
        withoutName: "Thank you for choosing %s 👍. Please, rate our services. Use the button below, press five stars and write few words."
    },
    {
        locale: "ru",
        title: "⭐⭐⭐⭐⭐ Пожалуйста, оцените %s",
        withName: "Привет, %s! Спасибо, что выбрали %s 👍. Пожалуйста, оцените нас. Нажмите кнопку ниже, поставьте пять звездочек и напишите несколько слов.",
        withoutName: "Спасибо, что выбрали %s 👍. Пожалуйста, оцените нас. Нажмите кнопку ниже, поставьте пять звездочек и напишите несколько слов."
    },
];

export const qrData = () => [
    {
        id: 1,
        title: "Download QR code",
        items: []
    },
    {
        id: 2,
        title: "Download table card",
        items: [
            {id: 2.1, image: '/assets/downloads/card-icon.png', name: 'Table card LV/RU', code: "card-LvRu", selected: false},
        ]
    },
    {
        id: 3,
        title: "Download A3 poster",
        items: [
            {id: 3.1, image: '/assets/downloads/a3-icon.png', name: 'Poster A3 LV/RU', code: "a3-LvRu", selected: false},
        ]
    },
    {
        id: 4,
        title: "Download TV banner",
        items: [
            {id: 4.1, image: '/assets/downloads/tv-icon.png', name: 'TV banner LV', code: "tv-Lv", selected: false},
        ]
    },
];
